a {
	color: var(--global-palette-highlight);
	transition: all .1s linear;
}
a:hover,
a:active {
	outline: 0;
}
.inner-link-style-plain a:not(.button), .widget-area.inner-link-style-plain a:not(.button) {
	text-decoration: none;
}
.inner-link-style-normal a:not(.button), .widget-area.inner-link-style-normal a:not(.button) {
	text-decoration: underline;
}
.link-style-no-underline  {
	p > a, strong > a, em > a, > ul a, a ,.editor-styles-wrapper a{
		text-decoration: none;
		&:hover {
			text-decoration: none;			
		}
	}
}
.link-style-color-underline  {
	p a, strong > a, em > a, > ul a, > ol a {
		color:inherit;
		text-decoration: underline;
		text-decoration-color:var(--global-palette-highlight);
		&:hover {
			color: var(--global-palette-highlight);
		}
	}
}
.link-style-hover-background {
	p a, strong > a, em > a, > ul a, > ol a {
		color: var(--global-palette-highlight);
		text-decoration: none;
		background-image: linear-gradient(var(--global-palette-highlight), var(--global-palette-highlight));
		background-position: 0% 100%;
		background-repeat: no-repeat;
		background-size: 100% 1px;
		&:hover {
			color: var(--global-palette-highlight-alt2);
			text-decoration: none;
			background-size: 100% 100%;
		}
	}
}
.link-style-offset-background {
	p a, strong > a, em > a, > ul a, > ol a {
		color:inherit;
		text-decoration: none;
		background-image: linear-gradient(var(--global-palette-highlight-alt2), var(--global-palette-highlight));
		background-repeat: no-repeat;
		background-position: 4px bottom;
		background-size: 100% 10px;
		&:hover {
			color:inherit;
			text-decoration: none;
			background-image: linear-gradient(var(--global-palette-highlight-alt2), var(--global-palette-highlight-alt));
		}
	}
}