@import "_custom-properties.scss";

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
	box-sizing: border-box;
}

/**
 * Inherit box-sizing to make it easier to change the property for
 * components that leverage other behavior; see
 * http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
	box-sizing: inherit;
}

hr {
	height: 0;
    border: 0;
    border-bottom: 2px solid var(--global-gray-400);
}

ul,
ol {
	margin: 0 0 1.5em 1.5em;
	padding: 0;
	&.aligncenter {
		list-style: none;
	}
}
ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

li > ul,
li > ol {
	margin-block-end: 0;
	margin-inline-start: 1.5em;
}

dt {
	font-weight: 700;
}

dd {
	margin: 0 1.5em 1.5em;
}

/* stylelint-disable */
img {
	display: block;
	height: auto;
	max-width: 100%;
}
/* stylelint-enable */

/* Extra wide images within figure tags don't overflow the content area. */
figure {
	margin: 0.5em 0;
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}