/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/

/* stylelint-disable */
button,
.button,
.wp-block-button__link,
input[type="button"],
input[type="reset"],
input[type="submit"] {

	background: var(--global-palette2);
	color: var(--global-palette9);
	padding: 0.4em 1em;
	border: 0;
	line-height: 1.6;
	display: inline-block;
	font-family: inherit;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.2s ease;
	box-shadow: 0px 0px 0px -7px rgba(0,0,0,0.0);
	&:visited {
		background: var(--global-palette2);
		color: var(--global-palette9);
	}
	&:hover, &:focus, &:active {
		color: var(--global-palette-btn-hover);
		background: var(--global-palette-btn-bg-hover);
		box-shadow: 0px 15px 25px -7px rgba(0,0,0,0.1);
	}
	&:disabled,
	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
}
.elementor-button-wrapper .elementor-button {
	background-color: var(--global-palette-btn-bg);
	color: var(--global-palette-btn);
	&:hover, &:focus, &:active {
		color: var(--global-palette-btn-hover);
		background-color: var(--global-palette-btn-bg-hover);
		box-shadow: 0px 15px 25px -7px rgba(0,0,0,0.1);
	}
}
.button.button-size-small {
	font-size: 80%;
	padding: 0.3em .7em;
}
.button.button-size-large {
	font-size: 110%;
	padding: 0.6em 1.2em;
}
.wp-block-button.is-style-outline .wp-block-button__link {
	padding: 0.4em 1em;
}
.button.button-style-outline, .wp-block-button.is-style-outline .wp-block-button__link {
	border: 2px solid currentColor;
	background: transparent;
	&:hover, &:focus, &:active {
		background: transparent;
		border: 2px solid currentColor;
		box-shadow: 0px 0px 0px -7px rgba(0,0,0,0.0);
	}
}
.wp-block-button.is-style-outline .wp-block-button__link:not(.has-text-color), .button.button-style-outline:not(.has-text-color) {
	color: var(--global-palette-btn-bg);
	&:hover, &:focus, &:active {
		color: var(--global-palette-btn-bg-hover);
	}
}