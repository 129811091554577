/**
 * Custom vars
 */
:root{
    --global-gray-400: #e5e5e5;
    --global-gray-500: #aaaaaa;
	--global-xs-spacing: 1em;
    --global-sm-spacing: 1.875rem;
    --global-md-spacing: 2rem;
    --global-lg-spacing: 2.5em;
    --global-xl-spacing: 3.5em;
    --global-xxl-spacing: 4rem;
    --global-boxed-spacing: 2rem;
}